import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bb736bad3218da043180d9b685acc58d/e1355/all-apps-i-use.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAExwed5jj//xAAaEAACAgMAAAAAAAAAAAAAAAABEgACAyEi/9oACAEBAAEFAjk0b8NDZRYsFn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BgX//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIxEBFR/9oACAEBAAY/Am0RLKN9x//EABkQAQEBAQEBAAAAAAAAAAAAAAERADFBcf/aAAgBAQABPyEwApIrzGUKorMpabyQx9y2Bc+t/9oADAMBAAIAAwAAABB7L//EABYRAQEBAAAAAAAAAAAAAAAAABEAAf/aAAgBAwEBPxB1J2//xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEhQf/aAAgBAgEBPxCh6KD/xAAcEAEAAwEAAwEAAAAAAAAAAAABABEhMUGxwdH/2gAIAQEAAT8QufMKBbzkRrCCX3yKEA6FcmnRya+QUZYtHI6/M//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "apps",
            "title": "apps",
            "src": "/static/bb736bad3218da043180d9b685acc58d/4b190/all-apps-i-use.jpg",
            "srcSet": ["/static/bb736bad3218da043180d9b685acc58d/e07e9/all-apps-i-use.jpg 200w", "/static/bb736bad3218da043180d9b685acc58d/066f9/all-apps-i-use.jpg 400w", "/static/bb736bad3218da043180d9b685acc58d/4b190/all-apps-i-use.jpg 800w", "/static/bb736bad3218da043180d9b685acc58d/e5166/all-apps-i-use.jpg 1200w", "/static/bb736bad3218da043180d9b685acc58d/b17f8/all-apps-i-use.jpg 1600w", "/static/bb736bad3218da043180d9b685acc58d/e1355/all-apps-i-use.jpg 4766w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
So this article is divided into 5 C(s) which are Create, Consume,
Coordinate, Connect and Cerebrate. Yes, this structure is from Ali Abdaal
but it works well so why not use it.`}</p>
    <p>{`CREATE`}</p>
    <p>{`This category contains all the pieces of software that I use in-order to
create stuff. That may be articles, websites, newsletters. So lets get
into it`}</p>
    <p>{`The first app is the one, the only Roam Research`}</p>
    <p>{`Roam Research is just such an awesome tool. You can implement the
Resonance Calendar System popularised by Ali Abdaal so efficiently.
the backlink functionality is just amazing. I use it daily and
extensively. if for some reason, I could use Roam Research, I would
have a pretty hard time.`}</p>
    <p>{`Here is a picture of my graph`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1328a2eecb48e8a8889c2805acd72a40/29114/roam-graph.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABlklEQVQoz02Txw4iQQxE5///hgM555wFImcQmQMgEQ61el41u0g97ul21dhVxvt+v7pcLlqv1xYPh4N2u52Ox6P2+702m83vfTKZaD6f29lsNtN2u9XpdNLtdtP5fNbr9ZJ3v981GAzUbrfV6XTUbDZVr9dt1Wo1VSoVVatVe8/n8yoUCnbWaDTU6/U0HA41Ho+1Wq30fr/lwbpcLtXv9y2xVCoZMJvNKpFIKB6PK5lMKp1OW0ylUspkMnZfLBbV7XatWqr+fD5/CReLhV1ASKIDQRaLxYw4Go0qFAopEonYnkgeXU2n03+Ej8dDo9Ho1xKE4XBYPp/PgOwBEwOBgEWI+RC5SEXL6I0fHg+ERicI+SoAgA78f2Q5CeiiXC6bXHRpGkJ4vV7tAEMckAocgdv7/X6TAQmcLOjYarWsqF+FWE7Z6IGLTnSAwWDQwG65u1wuZ3sWORjLz0NIKsQpRoHW+SLk6IPrziCqZA8Z8pALhlyKMlN4MNAMKMMNMQIzyAwu7nEOEEk4Z9D5A4AhF2Ofz6e1/AdRv7Rro2llnQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "roam graph",
            "title": "roam graph",
            "src": "/static/1328a2eecb48e8a8889c2805acd72a40/5a190/roam-graph.png",
            "srcSet": ["/static/1328a2eecb48e8a8889c2805acd72a40/772e8/roam-graph.png 200w", "/static/1328a2eecb48e8a8889c2805acd72a40/e17e5/roam-graph.png 400w", "/static/1328a2eecb48e8a8889c2805acd72a40/5a190/roam-graph.png 800w", "/static/1328a2eecb48e8a8889c2805acd72a40/c1b63/roam-graph.png 1200w", "/static/1328a2eecb48e8a8889c2805acd72a40/29007/roam-graph.png 1600w", "/static/1328a2eecb48e8a8889c2805acd72a40/29114/roam-graph.png 1920w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Notion`}</p>
    <p>{`This is the tool that I use for project management. I have a number of
projects going on, and Notion helps me to keep track of everything. I
don`}{`'`}{`t use it as much as I used to because Roam Research has
replaced many of its functions but still there are so functions of
Notion that I Roam Research does have. It sure is a great app`}</p>
    <p>{`OneNote`}</p>
    <p>{`As you know, I love Physics and Maths. I can use Latex and can type
kinda fast but still doing Physics and Maths in Latex is kinda
cumbersome. I am not going to solve whole problems in latex. I
don`}{`'`}{`t also like using paper, you can end up with piles of paper
which you are not sure whether to discard or keep. So, I use OneNote
and write using the Surface pen.`}</p>
    <p>{`PyCharm`}</p>
    <p>{`The main language that I use for programming is Python and in my
opinion, PyCharm is the best Python IDLE.`}</p>
    <p>{`Visual Studio Code`}</p>
    <p>{`I have very recently started to learn some app development using
flutter and I use Visual Studio Code as my IDLE of choice for that`}</p>
    <p>{`Sublime Text`}</p>
    <p>{`I have hand coded this website in HTML and CSS with a sprinkle of JS.
Sublime text is one of the best IDLE(s) for web development.`}</p>
    <p>{`The Command Prompt`}</p>
    <p>{`Fine this is mostly for LOLs. But yes I use the command prompt quite a
lot. I don`}{`'`}{`t use it that much now, but when I was learning Django,
I used it a lot.`}</p>
    <p>{`GitHub desktop`}</p>
    <p>{`This is the app that I use to communicate from my computer to my
GitHub repository. This site is hosted using GitHub pages so it helps
if I can easily change my code and commit changes.`}</p>
    <p>{`CONSUME`}</p>
    <p>{`I consume a lot of content such as Books, Audiobooks, Manga, Documentaries
and yes, YouTube videos. Here are the apps that I most often for consuming
content`}</p>
    <p>{`Audible`}</p>
    <p>{`Audible is the single best place on earth to get your audiobooks. It
is basically the price for knowledge you pay and it is totally worth
it. I love listening to audiobooks and you can check out my method for
consuming books and audiobooks here.`}</p>
    <p>{`Reddit`}</p>
    <p>{`Its the place for me to hangout for like 10 minutes a day. It is a
nice place to ask questions and get answers. It gives you that kind of
community feeling.`}</p>
    <p>{`Shonen Jump`}</p>
    <p>{`It is the best platform (at least in that I know of) to get some
manga. it has basically all the manga that you will wish to read.`}</p>
    <p>{`Google Podcasts`}</p>
    <p>{`My podcast platform of choice is Google podcasts. It is really simple
and it works well.`}</p>
    <p>{`Kindle`}</p>
    <p>{`I like to read and I use the Kindle apps and website to read my books`}</p>
    <p>{`Play books`}</p>
    <p>{`I like their upload feature. It is better than the kindle one
otherwise they are pretty similar.`}</p>
    <p>{`Twitter`}</p>
    <p>{`The default twitter app is my twitter client`}</p>
    <p>{`Twitter is under the consume category because I don`}{`'`}{`t really
Connect much using Twitter`}</p>
    <p>{`COORDINATE`}</p>
    <p>{`Day One`}</p>
    <p>{`It is literally the best journaling app.`}</p>
    <p>{`The android version is an afterthought for sure but still it is the
best. Just go get it`}</p>
    <p>{`Microsoft Edge (The new Beta one)`}</p>
    <p>{`It is the browser of my choice. I use a pretty simple Surface Go with
8 Gigs of RAM and frankly speaking, chrome is too heavy for it.`}</p>
    <p>{`Google Calender`}</p>
    <p>{`It is basically the calendar app of choice. I just use it to keep my
routine. It gives me a nice view of my routine. I haven`}{`'`}{`t really
tried a lot of calendar apps and I find that the Google Calendar app
works fine.`}</p>
    <p>{`OneDrive`}</p>
    <p>{`I backup my pictures, documents. It is a grate service and I love
using it. I would have gone for Google Drive but I subscribe to
Microsoft 365 and 1 TB of OneDrive storage is included so I just use
OneDrive.`}</p>
    <p>{`Power Toys`}</p>
    <p>{`It is a fun addon for windows. I love using PowerToys run. It is
basically the same as Windows search maybe even a touch worse but it
is just a coolness factor.`}</p>
    <p>{`I also like to use PowerToys`}{`'`}{` Fancy zones Feature.`}</p>
    <p>{`Habitica`}</p>
    <p>{`A Habit tracker is such a simple app that anyone can build it.
Therefore the stores are flooded with habit trackers. The reason I
prefer Habitica is the community side of things. You can join parties
and go on quests together to defeat bosses.`}</p>
    <p>{`CONNECT`}</p>
    <p>{`Gmail`}</p>
    <p>{`I don`}{`'`}{`t really care about E-Mail for now because I don`}{`'`}{`t get
that many. I just Gmail to mange the ones the emails that I receive.`}</p>
    <p>{`You can E-Mail me at `}<a parentName="p" {...{
        "href": "mailto:hi@aproductivenerd.com"
      }}>{`hi@aproductivenerd.com`}</a></p>
    <p>{`Whatsapp`}</p>
    <p>{`Exactly what is sounds like`}</p>
    <p>{`I use the desktop app because I can type way faster on my PC than on
my phone`}</p>
    <p>{`CEREBRATE`}</p>
    <p>{`Anki`}</p>
    <p>{`Anki is my flashcard app of choice. I use it to memorise random trivia
or facts. Anki is not for understanding, just for memorising. Use it
to memorise random facts not concepts`}</p>
    <p>{`Duolingo`}</p>
    <p>{`Duolingo is a free way for you to learn new languages. I am learning
Japanese and I love using Duolingo for that.`}</p>
    <p>{`Skillshare`}</p>
    <p>{`Skillshare is a online learning platform with a number of courses
ranging from Productivity to Graphic Design. I have learnt all my
HTML, CSS and JS from there and I love it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      